import React, { Component } from 'react'
import { NavLink } from 'react-router-dom'
import { itemTextData } from '../../data/item'
import { collectionsTextData } from '../../data/coll'
import ItemsListComponent from './ItemsListComponent'
import FirstBanner from './FirstBanner'
import SecondBanner from './SecondBanner'
import ThirdBanner from './ThirdBanner'
import FourthBanner from './FourthBanner'
// import ParisBanner from './ParisBanner'
// import MarketBanner from './MarketBanner'
// import HomelessPart2Banner from './HomelessPart2Banner'
// import ImageFader from '../common/ImageFader'

class ItemsList extends Component {
  constructor(props) {
    super()
    const { items, project } = props

    const types = []
    let defaultBg = true;

    items.forEach((item) => {
      const { type } = item
      if (!types.includes(type)) types.push(type)
    })

    if (project === 'market') {
      defaultBg = false
    }

    this.state = {
      types: types,
      typesSelected: [],
      toggle: false,
      bg: defaultBg
    }
  }

  componentDidMount() {
    // window.scrollTo(0, 0)

    // const element = document.getElementById('start');
    // element.scrollIntoView({ block: 'start', behavior: 'smooth'});

    // if (!this.state.bg) {
    //   document.documentElement.style.setProperty('--indigo-color', '#e42037')
    // } else {
    //   document.documentElement.style.setProperty('--indigo-color', 'rgb(62, 80, 157)')
    // }
  }

  render() {
    const { items,
            locale,
            moreStuffRoute,
            drate,
            changeLayout,
            project } = this.props

    const { types,
            typesSelected,
            toggle } = this.state

    const typesInfo = {
      en: {
        filter: 'Filter',
        accessories: 'Accessories',
        longsleeves: 'Longsleeves',
        shirts: 'Shirts',
        't-shirts': 'T-Shirts',
        pants: 'Pants',
        hoodies: 'Hoodies',
        bags: 'Bags',
        shoes: 'Shoes',
        dresses: 'Dresses',
        skirts: 'Skirts',
        jackets: 'Jackets',
        hats: 'Hats',
        tops: 'Tops',
        coats: 'Coats',
        sweaters: 'Sweaters',
        deaddybears: 'Deaddy Bears'
      },
      ru: {
        filter: 'Фильтр',
        accessories: 'Аксессуары',
        longsleeves: 'Лонгсливы',
        shirts: 'Рубашки',
        't-shirts': 'Футболки',
        pants: 'Штаны',
        hoodies: 'Худи',
        bags: 'Сумки',
        shoes: 'Обувь',
        dresses: 'Платья',
        skirts: 'Юбки',
        jackets: 'Куртки',
        hats: 'На голову',
        tops: 'Топы',
        coats: 'Пальто',
        sweaters: 'Свитера',
        deaddybears: 'Deaddy Bears'
      }
    }

    const filtersBlockHeight = toggle ? `${35 * types.length / 2 + 35}px` : '0px'

    const filteredItems = typesSelected.length ? items.filter(item => typesSelected.includes(item.type)) : items
    const firstEightElements = filteredItems.slice(0, 8)
    const secondEightElements = filteredItems.slice(8, 16)
    const thirdEightElements = filteredItems.slice(16, 24)
    const otherElements = filteredItems.slice(24)

    // let firstBanner = null

    // if (project === 'market') {
    //   firstBanner = <MarketBanner />
    // } else {
    //   firstBanner = <FirstBanner />
    // }

    const description =
      <div id='shopitems' className='items-container__description container-padding'>
        <h1 className='base-header'>
          {collectionsTextData[locale][project].name}
        </h1>
        <p className='base-text'>
          {collectionsTextData[locale][project].description}
        </p>
      </div>

    const filter =
      <div className='items-filter'>
        <button className='items-filter__header' onClick={this.toggleFilter}>
          {typesInfo[locale].filter}
        </button>
        <div className='items-filter__filters' style={{ height: filtersBlockHeight }}>
          {
            types.map((type) => {
              return (
                <div key={type} className='items-filter__checkbox'>
                  <label className='checkbox'>
                    <input
                      id={type}
                      onChange={this.onInputChange(type)}
                      className='checkbox__input'
                      type='checkbox'
                      checked={typesSelected.includes(type)}
                    />
                    <span className='checkbox__control'></span>
                    <span className='checkbox__label'>{typesInfo[locale][type]}</span>
                  </label>
                </div>
              )
            })
          }
        </div>
      </div>

    return (
      <div className='items-container'>
        {/*<div className='video'>
          <div className='video__text'>
            {saleTextData[locale].name}
          </div>

          <div className='video__inner'>
            <video className='video__v' width='100%' playsInline autoPlay muted loop>
              <source src='https://firebasestorage.googleapis.com/v0/b/indigostore-494ff.appspot.com/o/static%2Fsale11112022%2Fsale11112022.mp4?alt=media&token=aa6fe653-14b4-406c-a3c5-f0d30d969b03' type='video/mp4' />
            </video>
          </div>

        </div>*/}

        <FirstBanner />

        {description}

        {/*<ImageFader />*/}

        {/*<HomelessPart2Banner />*/}

        {/*<div className='items-container__banner'>
          <div>
            <NavLink to='/'>
              <img src='https://firebasestorage.googleapis.com/v0/b/indigostore-494ff.appspot.com/o/static%2Fmanicure-vampire%2Fmanicure-vampire-banner-1.png?alt=media&token=5dba5959-d860-45cc-b1b1-120178036a1a' width='2000' height='3000' alt='HOMELESS COLLECTION' />
            </NavLink>
          </div>
        </div>*/}

        {filter}

        <ItemsListComponent
          filteredItems={firstEightElements}
          locale={locale}
          changeLayout={changeLayout}
          drate={drate}
        />

        {/*<div className='items-container__banner'>
          <div>
            <NavLink to='/stuff'>
              <img src='https://firebasestorage.googleapis.com/v0/b/indigostore-494ff.appspot.com/o/static%2Fmanicure-vampire%2Fmanicure-vampire-banner-2.png?alt=media&token=f60408a5-c3e1-4503-bdf7-0d8a47379ebe' width='250' alt='HOMELESS COLLECTION' />
            </NavLink>
          </div>
        </div>*/}

        {/*<ImageFader />*/}

        <SecondBanner />

        <ItemsListComponent
          filteredItems={secondEightElements}
          locale={locale}
          changeLayout={changeLayout}
          drate={drate}
        />

        {/*<ThirdBanner /> */}

        <ItemsListComponent
          filteredItems={thirdEightElements}
          locale={locale}
          changeLayout={changeLayout}
          drate={drate}
        />

        <FourthBanner />

        <ItemsListComponent
          filteredItems={otherElements}
          locale={locale}
          changeLayout={changeLayout}
          drate={drate}
        />

        {
          moreStuffRoute ?
          <div className='items-list__more-btn-block'>
            <NavLink to={moreStuffRoute} className='button button--black'>
              {itemTextData[locale].moreStuff}
            </NavLink>
          </div> : null
        }
      </div>
    )
  }

  onInputChange = element => e => {
    const { typesSelected } = this.state

    if (typesSelected.includes(element)) {
      typesSelected.splice(typesSelected.indexOf(element), 1)
    } else {
      typesSelected.push(element)
    }

    this.setState({
      typesSelected: typesSelected
    })
  }

  toggleFilter = () => this.setState({ toggle: !this.state.toggle })
}

export default ItemsList
